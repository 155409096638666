<template>
    <div class="map-page map-page-list map-page-posts-list" :class="{'map-loading': loading }">
        <div class="map-page-content">
            <div class="map-admin-list">
                <header class="map-admin-list-header">
                    <h1 v-on:click="post_types_expanded = !post_types_expanded">List of {{ current_post_type }}</h1>
                    <ul
                        class="map-types-list"
                        :class="{ 'map-expanded': post_types_expanded }"
                        >
                        <li
                            v-for="(type, key) in post_types"
                            v-show="type !== 'lessons'"
                            :key="key"
                            :class="{ 'map-selected': type === current_post_type }"
                            v-on:click="change_post_type(type)"
                            >
                            {{ type }}
                        </li>
                    </ul>
                    <a :href="`/admin/${current_post_type}/create`" class="map-button map-color-primary map-button-edit">CREATE NEW {{ current_post_type }}</a>
                    <!-- <router-link class="map-button map-color-primary" :to="{ name: 'PostsCreate', params: { type: current_post_type } }" v-if="logged_user.role === 'super-admin'">CREATE NEW {{ current_post_type }}</router-link> -->
                    <panel
                        class="map-search-panel"
                        :dark="true"
                        >
                        <base-input
                            :round="true"
                            v-model="search_term"
                            :placeholder="`Search ${current_post_type}...`"
                            @change="filter_posts"
                            >
                        </base-input>
                        <Button class="map-search-button" icon="icon-search" color="primary" @click="filter_posts">
                            Search
                        </Button>
                    </panel>
                </header>
                <div class="map-table map-posts-table" :class="{ 'map-loading': loading }">
                    <header>
                        <div class="map-table-col">#</div>
                        <div class="map-table-col">Title</div>
                        <div class="map-table-col">Author</div>
                        <div class="map-table-col">Centers</div>
                        <div class="map-table-col">Language</div>
                        <div class="map-table-col">Tags</div>
                        <div class="map-table-col">Country</div>
                        <div class="map-table-col">Date</div>
                        <div class="map-table-col">Status</div>
                        <div class="map-table-col" v-if="logged_user.role === 'super-admin'">Actions</div>
                    </header>
                    <div class="map-table-body">
                        <loader />
                        <p class="map-table-empty" v-if="!has_posts && !loading">There are no items to display.</p>
                        <div
                            class="map-table-row"
                            v-for="(post, key) in filtered_posts"
                            :key="key"
                            :class="{'map-table-row-focus': visible_share_popup === post.id}"
                        >
                            <div class="map-table-col">{{ key + 1 }}</div>
                            <div class="map-table-col">{{ post.title }}</div>
                            <div class="map-table-col">{{ post.author.name }} {{ post.author.surname }}</div>
                            <div class="map-table-col">{{ post.centers.join(', ') }}</div>
                            <div class="map-table-col">{{ post.topic.join(', ') }}</div>
                            <div class="map-table-col">{{ post.tags.join(', ') }}</div>
                            <div class="map-table-col">{{ post.countries.map((i) => i.name).join(', ') }}</div>
                            <div class="map-table-col">{{ post.created_at }}</div>
                            <div class="map-table-col">{{ post.status }}</div>
                            <div class="map-table-col map-table-action-col map-no-bg" v-if="logged_user.role === 'super-admin'">
                                <button
                                    class="map-button map-color-primary map-button-share"
                                    @click="show_share_popup(post, key)"
                                    >
                                </button>
                                <div class="map-share-popup" v-if="visible_share_popup === post.id">
                                    <h5>Share {{ current_post_type }}</h5>
                                    <ul>
                                        <li :class="{'map-expanded': share_model.type === 'all'}" @click="share_model.type = 'all'">Share to all users</li>
                                        <li :class="{'map-expanded': share_model.type === 'email'}" @click="share_model.type = 'email'">
                                            Share via email
                                            <base-input
                                                type="email"
                                                placeholder="Email"
                                                v-model="share_model.email"
                                                v-if="share_model.type === 'email'"
                                                >
                                            </base-input>
                                        </li>
                                        <li :class="{'map-expanded': share_model.type === 'public'}" @click="share_model.type = 'public'">
                                            Copy public link
                                            <base-input
                                                placeholder="Public link"
                                                v-model="share_model.public_link"
                                                disabled
                                                @click="select_all"
                                                v-if="share_model.type === 'public'"
                                                >
                                            </base-input>
                                        </li>
                                    </ul>
                                    <div class="map-share-actions">
                                        <button
                                            v-if="share_model.type !== 'public'"
                                            class="map-button map-color-primary map-button-share-post"
                                            @click="share_post(post, key)"
                                            >
                                            Share
                                        </button>
                                        <!-- <button
                                            v-else
                                            class="map-button map-color-primary"
                                            @click="generate_public_link(post, key)"
                                            :disabled="share_model.public_link !== ''"
                                            >
                                            Generate public link
                                        </button> -->
                                        <button
                                            class="map-button map-color-primary"
                                            @click="show_share_popup(post, key)"
                                            >
                                            Close
                                        </button>
                                    </div>
                                </div>
                                <a :href="`/admin/${current_post_type}/${post.id}/edit`" class="map-button map-color-primary map-button-edit"></a>
                                <!-- <router-link
                                    class="map-button map-color-primary map-button-edit"
                                    :to="{ name: 'PostsEdit', params: { type: this.current_post_type, id: post.id } }"
                                    ></router-link> -->
                                <button
                                    class="map-button map-color-primary map-button-remove"
                                    @click="remove_post(post, key)"
                                    >
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from '@/components/Buttons/Button';
    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';
    import Loader from '@/components/Loader';

    import { PostService, PostType, ShareService } from '@/services';

    export default
    {
        name: 'PostsList',
        components:
        {
            Button,
            Panel,
            BaseInput,
            Loader,
        },
        data()
        {
            return {
                loading: false,
                has_posts: false,
                post_types_expanded: false,
                current_post_type: this.$route.params.post_type || PostType.BLOG,
                posts: [],
                search_term: '',
                visible_share_popup: 0,
                share_model:
                {
                    type: 'all',
                    email: '',
                    public_link: '',
                },
                filtered_posts: [],
                post_types: PostType,
            };
        },
        computed:
        {
            logged_user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        async mounted()
        {
            await this.get_posts();
        },
        methods:
        {
            filter_posts()
            {
                this.filtered_posts = this.posts.filter((post) =>
                {
                    return JSON.stringify(post).toLowerCase().includes(this.search_term.toLowerCase());
                });

                this.has_posts = this.filtered_posts && this.filtered_posts.length;
            },

            async get_posts()
            {
                this.loading = true;

                this.has_posts

                this.posts = (await PostService.all(this.current_post_type,
                    {
                        all: 1,
                        exclude: 'summary,content,lessons,comments',
                        'per-page': -1
                    })).data.data;

                this.has_posts = this.posts && this.posts.length;

                this.filtered_posts = this.posts;

                this.loading = false;
            },

            async change_post_type(type)
            {
                this.current_post_type = type;
                this.post_types_expanded = false;

                await this.get_posts();
            },

            async remove_post(post, index)
            {
                if (confirm('Are you sure you want to remove this post?'))
                {
                    await PostService.remove(this.current_post_type, post.id);

                    this.posts.splice(index, 1);
                }
            },

            async show_share_popup(post)
            {
                if (this.visible_share_popup === post.id)
                {
                    this.visible_share_popup = 0;
                    this.share_model =
                    {
                        type: 'all',
                        email: '',
                        public_link: '',
                    };
                }
                else
                {
                    const response = await ShareService.get_by_post(post.id, this.current_post_type);

                    if (response && response.data.token !== '')
                    {
                        this.share_model.public_link = `${window.location.protocol}//${window.location.host}/shared/public/${response.data.token}`;
                    }
                    else
                    {
                        this.generate_public_link(post);
                    }

                    this.visible_share_popup = post.id;
                }
            },

            async share_post(post)
            {
                let message = 'Are you sure you want to share this post?';

                if (this.share_model.type === 'all')
                {
                    message = 'Are you sure you want to share this post to all users?';
                }

                if (confirm(message))
                {
                    await ShareService.share(post.id, this.current_post_type, this.share_model).then(() =>
                    {
                        if (this.share_model.type === 'all')
                        {
                            this.$toast.success('Post was successfully shared with all users!');
                        }
                        else
                        {
                            this.$toast.success('Post was successfully shared!');
                        }

                        this.visible_share_popup = 0;
                        this.share_model =
                        {
                            type: 'all',
                            email: '',
                            public_link: '',
                        };
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
            },

            async generate_public_link(post)
            {
                const response = await ShareService.generate_public_link(post.id, this.current_post_type);

                this.share_model.public_link = `${window.location.protocol}//${window.location.host}/shared/public/${response.data.token}`;
            },

            select_all(event)
            {
                event.target.select()
            },
        },
    }
</script>
